<template>
  <div class="container">
    <div class="box">
      <div class='title'>
        {{titleInfo}}
      </div>
    </div>
     <div class='meetTime'>
      <span>会议时间</span>
      <span class="time-msg">{{timeinfo}}</span>
    </div>
    <div>
      <div class='choose_list' :class="this.iosVersion && this.iosVersion<15 ?'disFunc':''">
        <div class="set-item ">
          <!-- 开启麦克风 -->
          <span class="label">{{$t('login.openMicrophone')}}</span>
          <van-switch class="switch" active-color="#1AB370" inactive-color="#d9d9d9" v-model="isEnableMicro" :disabled="this.iosVersion && this.iosVersion<15"
            :size="swithSize" />
        </div>
        <div class="set-item second">
          <!-- 开启摄像头 -->
          <span class="label">{{$t('login.openCamera')}}</span>
          <van-switch class="switch" active-color="#1AB370" inactive-color="#d9d9d9" v-model="isEnableVideo" :disabled="this.iosVersion && this.iosVersion<15"
            :size="swithSize" />
        </div>
      </div>
      <join-button class="join" :disabled="this.iosVersion && this.iosVersion<15" @join-finish="joinFinishHandle">{{$t('home.joinMeeting')}}</join-button>
      <div class="appJoin"  v-if="copyOpenAppMsg && appMeetingEnable">
        <div class="lowVerIosText" v-if="this.iosVersion && this.iosVersion<15">会议小程序暂不支持iOS 15以下系统</div>
        <van-button type="primary" v-clipboard:copy="copyOpenAppMsg" v-clipboard:success="onCopyLink"
        v-clipboard:error="onError" class="copyButton">
        医百会议App入会
        </van-button>
        <div class="copymsg">请您复制口令后打开“医百会议”App</div>
      </div>

      <div class="downloadmore" v-if="copyOpenAppMsg && appMeetingEnable"  @click="showDownloadWarp=true">还没下载医百会议？<span>点击查看</span></div>
    </div>

    <van-popup v-model:show="showDownloadWarp" class="downloadBox" duration="0.2" position="bottom">
      <div class="downloadWarp">
        <my-icon 
          fontSize="48" 
          class="closeBtn"
          iconName="icon-xitonglan_guanbi_48_hei"
          @click="showDownloadWarp=false"
        ></my-icon>
      
        <div class="downloadText">医百会议App下载指南</div>

        <!-- 下载医百会议 -->
        <div class="downloadContent">
          <div class="downloadTitle">iOS用户请在商店搜索“医百会议”下载使用</div>
        </div>
  
        <div class="downloadContent">
          <div class="downloadTitle">安卓用户请在华为、荣耀、小米、oppo、vivo、应用宝等商店搜索“医百会议”下载使用，或复制链接在手机浏览器打开</div>
          <div class="downloadBtn" v-if="copyDownAndRoidMsg" v-clipboard:copy="copyDownAndRoidMsg" v-clipboard:success="onCopy"
          v-clipboard:error="onError">复制链接</div>
        </div>

        <van-button type="primary" class="copyButton"  @click="showDownloadWarp=false">确定</van-button>

      </div>
    </van-popup>

    
  </div>
</template>

<script>
  import {
    strToBoolean
  } from '@/utils/str'
  import {
    loadLocal,
    saveLocal,
    removeLocal,
    isArray,
    formatCode,
    getUrlDate,
    getDate
  } from '@/utils/tool'
  import {
    getLocalConferenceNo,
    setLocalConferenceNo,
    getDisplayName,
    setDisplayName,
    getLocalUserInfo,
    setMeetToken,
    getMeetToken,
  } from '@/utils/auth'
  import {
    joinConference,
    landingToken,
    getAndroidDownLoadURL,
    landingConfirm
  } from '@/api/meet'

  import JoinButton from '@/components/button/JoinButton'
  import Base64 from "crypto-js/enc-base64";
  import CryptoJS from "crypto-js";

  export default {
    data() {
      return {
        isEnableMicro: true,
        isEnableVideo: false,
        showDownloadWarp: false,
        titleInfo: '',
        timeinfo: '',
        copyOpenAppMsg:'',
        copyDownAndRoidMsg:'',
        swithSize:24,
        iosVersion:null,
        appMeetingEnable:true //是否开启app入户入口
      }
    },

    components: {
      JoinButton
    },
    created() {
      this.getRouter()
      this.getDownLoadAndroidURL()
      let ver = (navigator.appVersion).match(/OS (\d+)_(\d+)_?(\d+)?/);
      if(ver){
        this.iosVersion = parseInt(ver[1], 10);
      }
    },
    mounted() {
      //van-switch 的大小根据页面文字大小调整
      let fontSize = window.getComputedStyle(document.querySelector('.title')).fontSize.split('px')[0]
      this.swithSize = fontSize * 1.6
    },
    methods: {
      async getRouter() {
        // let url = 'https://mopan-wechat.test.100url.cn/join?token=eJizJyFQovRq_9a21befc-f048-4036-a022-f35f150d6bb4'
        let url = window.location.href

        let paramsArr = getUrlDate(url)
        if (paramsArr.token) {
          setMeetToken(paramsArr.token)
        }
        try {
          const resData = await landingToken({
            token: getMeetToken()
          })

          console.log('当前token 校验相关信息1', resData)

          if (resData.info) {
            this.titleInfo = resData.info.topic
            let startTime = new Date(resData.info.planStartTime)
            let endTime = new Date(resData.info.planEndTime)
            let ymd = getDate(startTime)
            let _endYmd = getDate(endTime)
            this.timeinfo = `${ymd} ${startTime.toTimeString().substr(0, 5)}~${getDate(endTime)!=getDate(startTime) ? _endYmd + ' ' : ''}${ endTime.toTimeString().substr(0,5)}`
            if(typeof resData.appMeetingEnable == 'boolean'){
              this.appMeetingEnable = resData.appMeetingEnable
            }
            //获取拷贝口令
            if(this.appMeetingEnable){
              this.getCopyMsg()
            }
          }
          if(!resData.success) {
            this.$dialog.confirm({
              message: resData.error,
              confirmButtonText: '确定',
              closeOnPopstate: false,
              showCancelButton:false,
            })
          }
        } catch (error) {
          //todo错误处理
          console.error('landingToken error',error,navigator.userAgent)
          if(error.message != 'Network Error'){
            this.$toast(error)
          }
        }

      },
      // 加入会议完成回调
      joinFinishHandle(val) {
        saveLocal("isUseHuaTong", this.isEnableMicro);
        saveLocal("isUseShiPin", this.isEnableVideo);

        this.conferenceNo = formatCode(val.conference.conferenceNo)

        setLocalConferenceNo(this.conferenceNo)
        setDisplayName(this.userName)
      },
      onCopyLink() {
        this.$toast('已复制，请打开医百会议APP参与会议')
      }, 
      onCopy() {
        this.$toast(this.$t('seeting.succCopy'))
      },
      // 复制失败时的回调函数
      onError(e) {},
      //获取安卓下载地址
      async getDownLoadAndroidURL(){
        let res = await getAndroidDownLoadURL()
        // console.error(11111111,res)
        if(res && res.downloadUrl){
          this.copyDownAndRoidMsg = res.downloadUrl
        }else{
          setTimeout(() => {
            this.getDownLoadAndroidURL()
          }, 3000);
        }
      },
      async getCopyMsg(){
        try {
          const resData = await landingConfirm({
            token: getMeetToken()
          })
          if (resData.success) {
            let _date = {}
            _date.conferenceNo = resData.info.code
            _date.displayName = resData.info.username
            _date.uid = resData.info.userId
            _date.businessType = 1
            _date.questionnaireUrl = resData.info.questionnaireUrl
            if (resData.info.requirePassword) {
              _date.password = resData.info.password ?  resData.info.password :''
            }
            if(resData.info.appIdList){
              _date.appId = resData.info.appIdList
            }
            if(resData.info.onlineWatermark){
              _date.onlineWatermark = resData.info.onlineWatermark
            }
            if(resData.info.mobile){
              _date.mobile = resData.info.mobile
            }
            console.error('getCopyMsg',resData)
            console.error('copyData',_date)
            
            this.copyOpenAppMsg = `【医百会议】|${Base64.stringify(CryptoJS.enc.Utf8.parse(JSON.stringify(_date)))}|复制该信息，打开手机医百会议App即可参与。
请勿将您的专属入会信息分享给他人。`
          } else {
            if(resData.error.includes('会议已结束') || resData.error.includes('会议不存在')){
              // this.$router.push({
              //   name: "endJoin",
              // });
            }else{
              this.$dialog.confirm({
                message: '获取复制信息错误：' + resData.error,
                confirmButtonText: '确定',
                closeOnPopstate: false,
                showCancelButton:false,
              })
            }
          }
        } catch (error) {
          //todo 错误处理
          this.$toast('获取复制信息错误：' + error.msg)
        }
      }
    },
  }

</script>

<style scoped lang="less">
  .container {
    width: 100%;
    height: 100%;
    padding-top: 16px;
    margin: 0 auto;
    background-color: #fafafaFF;


    .box {
      position: relative;
      width: 100%;
      height: 200px;
      font-size: 32px;
      background: #FFFFFF;
      border-top: 1px solid #E5E5E5FF;
      border-bottom: 1px solid #E5E5E5FF;
      box-sizing: border-box;
      overflow: hidden;
      word-break: break-all;

      .title {
        width: 100%;
        height: 135px;
        width: 92%;
        height: 135px;
        overflow-y: scroll;
        position: absolute;
        text-align: center;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        word-break: break-all;

      }
    }

    .meetTime {
      display: flex;
      justify-content: space-between;
      margin: 16px 0;
      border-top: 1px solid #E5E5E5FF;
      border-bottom: 1px solid #E5E5E5FF;
      min-height: 104px;
      background: #FFFFFF;
      padding: 30px;
      font-size: 32px;
      color: #333333;
      .time-msg{
        flex: 1;
        text-align: right;
      }
    }

    .choose_list {
      width: 100%;
      background: #FFFFFF;
      border-top: 1px solid rgba(229, 229, 229, 0.5);
      border-bottom: 1px solid rgba(229, 229, 229, 0.5);
      padding: 0 30px;

      .set-item {
        height: 104px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .label {
          display: inline-block;
          font-size: 28px;
          font-weight: 400;
          color: #333333;
        }

        .coppyWarp{
          flex: 1;
          display: flex;
          justify-content: end;
          font-family: PingFangSC-Regular, PingFang SC;
          font-size: 28px;
          font-weight: 400;
          line-height: 40px;
          .coppyTitle{
            margin-right: 29px;
            color: #999999;
            line-height: 40px;
            text-align: right;
            flex: 1;
          }
          .coppyBtn{
            color: #1AB370;
          }
        }
      }

      .second {
        border-top: 1px solid rgba(229, 229, 229, 0.5);
      }
    }
    .disFunc{
      pointer-events: none;
      .set-item {
        .label {
          color: #C0C0C0;
        }
        .coppyWarp .coppyBtn{
          color: red;
        }
      }
    }
    .end_meet {
      font-size: 36px;
      color: #666666;
      text-align: center;
      margin-top: 215px;
    }

    .join {
      width: 83%;
      margin: 0 auto;
      margin-top: 134px;
      margin-bottom: 46px;
    }
  }
  .appJoin{
    text-align: center;
    width: 100%;
    position: relative;
    .lowVerIosText{
      position: absolute;
      width: 100%;
      left: 0;
      top: -40px;
      text-align: center;
      font-size: 24px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #FF4D4F;
      line-height: 33px;
    }

    .copymsg{
      font-size: 24px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #999999;
      line-height: 33px;
      margin-top: 16px;
    }
  }
  .copyButton {
    display: inline-block;
    width: 83%;
    height: 88px;
    background: #1AB370;
    color:#fff;
    border-radius: 10px;
    font-size: 32px;
    font-weight: 500;
    border:none;
  }
  .downloadmore{
    position:fixed;
    bottom: 24px;
    left: 0;
    width: 100%;
    height: 96px;
    text-align: center;
    font-size: 24px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #999999;
    line-height: 96px;
    span{
      color: #1AB370;
    }
  }
  .downloadBox{
    border-radius: 10px 10px 0 0;
    height: 512px;
    .downloadWarp{
      padding: 24px 30px 20px 30px;
      height: 100%;
      text-align: center;
      position: relative;
      .closeBtn{
        position: absolute;
        top: 24px;
        right: 24px;
      }
      .downloadText{
        height: 36px;
        font-size: 32px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 36px;
        margin-bottom: 45px;
      }
      .label{
        height: 64px;
        margin: 0;
        font-size: 24px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 64px;
      }
      .downloadContent{
        width: 100%;
        background: #FFFFFF;
        font-size: 28px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
        padding: 30px 0;
        line-height: 34px;
        display: flex;
        justify-content: space-between;
        text-align: left;
        &:nth-of-type(2){
          border-bottom: 1px dashed rgba(229, 229, 229, 0.5);
        }
        .downloadTitle{
          flex: 1;
        }
        .downloadBtn{
          padding-top: 34px;
          color: #1AB370;
        }
      }
      .copyButton{

        margin-top: 42px;
      }
    }

  }

</style>
